// @import-normalize;

// html,
// body {
//   height: 100%;
//   margin: 0;
//   overflow: auto;
//   padding: 0;
//   width: 100%;
// }

:global {
  #root {
    height: 100%;
  }

  input[type="text"],
  input,
  textarea {
    box-sizing: border-box;
  }
}

:global {
  html,
  body {
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 95.5%;
    margin: 0;
    // overflow: hidden;
  }

  body {
    overflow: hidden;
  }

  #root {
    overflow: auto;
  }

  a {
    color: #337ab7;
    text-decoration: none;
  }
}

:global html {
  font-size: 95.5%;
  scroll-behavior: smooth;
  height: 100%;
}

body {
  color: #5c666f;
  font-size: 13px;
  height: 100%;
}

a:hover {
  // color: #0095a0;
  text-decoration: underline;
}

@media only screen and (min-width: 768px) {
  body {
    font-size: 14px;
  }
}
